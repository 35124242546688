import React, {useEffect} from 'react';
import {setPreloader} from '../../redux/actions';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {config} from '../../data';
import * as auth from '../../helpers/authenticationHelper';
import {buildAvatarUrl} from '../../helpers'; // https://lodash.com/docs/4.17.4#isEmpty

const DATE = {
  date: new Date(),
  interval_of_years: 99,
  set_current_year() {
    return this.date.getFullYear();
  },
  set_starting_year() {
    return this.date.getFullYear() - this.interval_of_years
  }
};

DATE.set_current_year.bind(DATE);
DATE.set_starting_year.bind(DATE);

const ChildForm = (props) => {
  const {
    categories,
    onCheckboxChange,
    onFormChange,
    onAvatarChange,
    avatar_id,
    child,
    onFormSubmit,
    is_add_new_child_page,
  } = props;

  let categoriesItems = null;
  let avatar = null;
  let birthdayData = null;
  let addNewChild = null;

  if (categories) {
    categoriesItems = categories.map((item, index) => {
      return <label
        className="topic-list__item"
        key={index}
      >
        <input
          type="checkbox"
          name={index}
          className="topic-list__item-input"
          onChange={onCheckboxChange}
          checked={item.is_checked}
        />
 {/*       <i className="topic-list__item-icon"/>*/}
        <img
          style={{border:`${item.is_checked ? 4 : 0}px solid aquamarine`,borderRadius:'50%'}}
          src={`${config.base_url}${item.icon}`}
          alt={`${item.name}`}
        />
        <span className="topic-list__item-text">{item.name}</span>
      </label>
    })
  }

  if (child) {
    let ava = buildAvatarUrl('');
    let user = auth.getProfile();

    if (user) {
      let child = user.children.find(c => c.id === user.currentChildId);
      ava = buildAvatarUrl(child);
    }

    avatar = config.avatars.map((item, index) => <label
        key={index}
        className={`avatar-list__item ${avatar_id === item.toString() ? 'avatar-list__item_current' : ''}`}
      >
        <input
          type="radio"
          name="avatar"
          value={item}
          onChange={onAvatarChange}
          checked={avatar_id === item.toString()}
        />
        <span
          className="avatar-list__item-img"
          style={{
            backgroundImage: ava
          }}
        />
        {/* <Icon name={`#cabinet--avatar_${item}`} width='64px' height='64px'/> */}
      </label>
    );

    // если дата рождения не заполнена
    let today = new Date();
    let day = today.getDate();
    let month = today.getMonth() +1;
    const year = today.getFullYear();
    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;
    const todayDate= year + "-" + month + "-" + day;
    const minDate= (year-15) + "-" + month + "-" + day;

    birthdayData = <div className="child-form__right">
      <div className="child-form__birthday">
        <input
          style={{width:'200px'}}
          min={minDate} max={todayDate}
          className="child-form__input"
          name="birthday"
          type="date"
          placeholder="ДД.ММ.ГГГГ"
          onChange={onFormChange}
        />
      </div>

      {/*<div className="child-form__birthday">*/}
      {/*  <input*/}
      {/*    className="child-form__input"*/}
      {/*    type="number"*/}
      {/*    min="1"*/}
      {/*    max="12"*/}
      {/*    name="month"*/}
      {/*    placeholder="ММ"*/}
      {/*    onChange={onFormChange}*/}
      {/*  />*/}
      {/*</div>*/}

      {/*<div className="child-form__birthday">*/}
      {/*  <input*/}
      {/*    className="child-form__input"*/}
      {/*    type="number"*/}
      {/*    name="year"*/}
      {/*    min={DATE.set_starting_year()}*/}
      {/*    max={DATE.set_current_year()}*/}
      {/*    placeholder="ГГГГ"*/}
      {/*    onChange={onFormChange}*/}
      {/*  />*/}
      {/*</div>*/}
    </div>;

    if (!is_add_new_child_page) {
      addNewChild = <Link
        to={`/cabinet/${config.routes.add_new_child}`}
        className="child-form__top-btn cabinet__add-child"
      >
        Добавить ещё ребёнка
      </Link>
    }
  }

  useEffect(() => {
    //setPreloader('');
  }, [])

  return (
    <form className="child-form" onSubmit={onFormSubmit}>
      <div className="wrapper wrapper_cabinet">
        <fieldset className="child-form__inner">

          <div className="child-form__top">
            <h3 className="child-form__title">Расскажите о ребёнке</h3>
            {/*{addNewChild}*/}
          </div>

          {/* Ававатар */}

          {/*<div className="child-form__group child-form__group_type_avatar">*/}
          {/*  <label className="child-form__label">Аватар</label>*/}
          {/*  <div className="child-form__right">*/}
          {/*    <div className="avatar-list">*/}
          {/*      {avatar}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/* Ававатар */}

          {/* Имя */}

          <div className="child-form__group">
            <label className="child-form__label">Имя</label>

            <div className="child-form__right">
              <input
                className="child-form__input"
                placeholder={`${child.name ? child.name : ''}`}
                name="name"
                type="text"
                onChange={onFormChange}
                required={is_add_new_child_page}
              />
            </div>
          </div>

          {/* Имя */}

          {/* День рождения */}

          <div className="child-form__group child-form__group_type_birthday">
            <label className="child-form__label">День рождения</label>
            {birthdayData}
          </div>

          {/* День рождения */}

          {/* Интересы */}

          <div className="child-form__group">
            <label className="child-form__label">Интересы</label>

            <div className="child-form__right">

              <div className="topic-list">

                {categoriesItems}

              </div>
            </div>
          </div>

          {/* Интересы */}

        </fieldset>
      </div>
      <div className="child-form__controls">
        <div className="child-form__bottom">
          <span onClick={() => {
            window.history.back()
          }} className="infant-garb__button __red"><span>Назад</span></span>
          <button type="submit" className="infant-garb__button __blue">
            <span>{is_add_new_child_page ? 'Добавить' : 'Сохранить'}</span></button>
        </div>
      </div>
    </form>
  );
};

ChildForm.propTypes = {
  categories: PropTypes.array,
  onCheckboxChange: PropTypes.func,
  onFormChange: PropTypes.func,
  onAvatarChange: PropTypes.func,
  onFormSubmit: PropTypes.func,
  avatar_id: PropTypes.string,
  child: PropTypes.object,
  is_add_new_child_page: PropTypes.bool
};

const mapDispatchToProps = {
  setPreloader
};

const componentWithRouter = withRouter(connect(null, mapDispatchToProps)(ChildForm))

export {componentWithRouter as ChildForm};
