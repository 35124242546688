import React from 'react';
import PropTypes from 'prop-types';
import {config} from '../../data';
import { Link } from 'react-router-dom';

export const ChildQuest = (props) => {
  const {
    lesson
  } = props;

  return (
    <Link
      title={lesson.name}
      to={lesson.quest && lesson.quest.id ? `/${config.routes.quests}/${lesson.quest.id}` : `/lessons/${lesson.id}`}
      className={`child-quest child-quest_state_${lesson.access.is_open ? 'normal' : 'star'} child-quest_state_${lesson.test.is_completed ? 'finished' : 'not_finished'}`}
    >
      <img alt={'icon'} src={`${config.base_url}${lesson.map.icon}`} role="presentation"/>
    </Link>
  );
};

ChildQuest.propTypes = {
  lesson: PropTypes.object
};
