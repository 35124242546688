import { ajaxV2 } from '../../helpers';

export const getPersonStatistics = (childId, lessonId) => {
  return ajaxV2
    .get(`/persons?child_id=${childId}&lesson_id=${lessonId}`)
    .then(response => {
      let result = response.data.filter(item => item.fact && item.fact.options && item.fact.options.is_fact)
      result = result.map(item => {
        return {
          id: item.id,
          dom_id: item.dom_id,
          status: item.status || 1
        }
      })

      window.devMode && console.log('getPersonStatistics', result);

      return result
    })
    .catch(err => {
      window.devMode && console.log('getPersonStatistics error: ', err)
    })
};
