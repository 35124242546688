import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {closeAuthPopup} from '../../redux/actions';
import {isTouch} from '../../helpers';

import SingUpNewContainer from '../../containers/auth/sign-up-new';
import LoginContainer from '../../containers/auth/login';
import EnterContainer from '../../containers/auth/enter';
import AuthPasswordReset from '../../containers/auth/password-reset';
import AuthPasswordCreate from '../../containers/auth/password-create';

import {
  DialogGames,
  DialogLessons,
  DialogPayment,
  DialogFeedback,
  DialogAuth,
  DialogApprovement,
  DialogCheck,
  DialogVideo,
  PaymentTariff,
  WinterSale,
  ThirdDayPromo,
  SpringSale
} from '../../components';
import {GoldSale} from '../gold-sale';
import {UnicornSale} from '../unicorn-sale';
import {LockdownSale} from '../lockdown-sale';
import {BlackFridaySale} from '../blackfriday-sale';
import {NewYearSale} from '../new-year-sale';

class Popup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  closePopupHandler = () => {
    this.props.closeAuthPopup('login');
    this.props.closeAuthPopup('signup');
    this.props.closeAuthPopup('password-reset');
    this.props.closeAuthPopup('password-create');
    this.props.hidePopUp();
  }

  render() {
    const {
      hidePopUp,
      is_closed,
      focus,
      selectedTariff,
      popupData,
      goToLink,
      skin,
      time,
      item: {url, preview, section, status, text, title, btnTitle}
    } = this.props;

    let dialog = null;
    let authPopup = null;

    if (section === 'games') {
      dialog = <DialogGames status={status} preview={preview} url={url}/>;
    }

    if (section === 'lessons') {
      dialog = (
        <DialogLessons
          status={status}
          preview={preview}
          text={text}
          title={title}
          url={url}
        />
      );
    }

    if (section === 'payment_status') {
      dialog = (
        <DialogPayment
          title={title}
          text={text}
          btnTitle={btnTitle}
          hidePopUp={hidePopUp}
        />
      );
    }

    if (section === 'password_approve') {
      dialog = <DialogApprovement hidePopUp={hidePopUp}/>;
    }

    if (section === 'feedback') {
      dialog = <DialogFeedback/>;
    }

    if (section === 'video') {
      dialog = <DialogVideo hidePopUp={hidePopUp}/>;
    }

    if (section === 'check') {
      dialog = <DialogCheck hidePopUp={hidePopUp} focus={focus}/>;
    }

    if (section === 'payment-tariff') dialog = <PaymentTariff popupData={popupData} selectedTariff={selectedTariff}>
      <h3 className="payment-tariff__header">«ПРИКЛЮЧЕНИЯ ЧЕВОСТИКА» – ЭТО:</h3>
      <ul className="payment-tariff__list">
        <li className="payment-tariff__item">доступ к 400+ играм, тестам и приключениям</li>
        <li className="payment-tariff__item">таблица знаний, успехов и интересов вашего ребёнка</li>
        <li className="payment-tariff__item">уникальные подборки материалов на почту</li>
      </ul>
    </PaymentTariff>;

    if (section === 'coin-shop') dialog = <PaymentTariff popupData={popupData} selectedTariff={selectedTariff}>
      <h3 style={{margin: '30px 0'}} className="payment-tariff__header">Пополнение счёта <br/> в «ПРИКЛЮЧЕНИЯХ
        ЧЕВОСТИКА»</h3>
    </PaymentTariff>;

    if (section === 'new-year' && window.location.search.indexOf('popup=') === -1) {
      dialog = (
        <NewYearSale
          goToLink={goToLink}
          time={time}
        />
      );
    }

    // Чёрная пятница
    if (section === 'blackFriday' && window.location.search.indexOf('popup=') === -1) {
      dialog = <BlackFridaySale goToLink={goToLink} />
    }

    // Новый год
    if (section === 'newYear' && window.location.search.indexOf('popup=') === -1) {
      dialog = <NewYearSale goToLink={goToLink} />
    }

    if (section === 'spring' && window.location.search.indexOf('popup=') === -1) {
      dialog = (
        <SpringSale
          goToLink={goToLink}
          time={time}
        />
      );
    }

    if (section === 'lockdown' && window.location.search.indexOf('popup=') === -1) {
      dialog = (
        <LockdownSale
          goToLink={goToLink}
          time={time}
        />
      );
    }

    if (section === 'winter' && window.location.search.indexOf('popup=') === -1) {
      dialog = (
        <WinterSale
          goToLink={goToLink}
          time={time}
        />
      );
    }

    if (section === 'gold' && window.location.search.indexOf('popup=') === -1) {
      dialog = (
        <GoldSale
          skin={skin}
          goToLink={goToLink}
          time={time}
        />
      );
    }

    if (section === 'unicorn' && window.location.search.indexOf('popup=') === -1) {
      dialog = (
        <UnicornSale
          skin={skin}
          goToLink={goToLink}
          time={time}
        />
      );
    }

    if (section === 'summer' && window.location.search.indexOf('popup=') === -1) {
      dialog = (
        <WinterSale
          goToLink={goToLink}
          time={time}
        />
      );
    }

    if (section === 'thirdDayPromo' && window.location.search.indexOf('popup=') === -1) {
      dialog = (
        <ThirdDayPromo
          goToLink={goToLink}
          time={time}
        />
      );
    }

    if (this.props.location) {
      if (section === 'enter') {
        dialog = (
          <DialogAuth preview="enter">
            <EnterContainer location={this.props.location}/>
          </DialogAuth>
        );
      }

      //if (section === 'password-reset') {
      if (this.props.popups.isPasswordResetPopupVisible) {
        authPopup = 'password-reset';
        dialog = <AuthPasswordReset location={this.props.location}/>
      }

      /*   if (section === 'signup') {
        dialog = (<DialogAuth preview="signup" >
                    <SingUpContainer location={this.props.location} />
                  </DialogAuth>)
      } */

      //if (section === 'password-create') {
      if (this.props.popups.isPasswordCreatePopupVisible) {
        dialog = (
          <DialogAuth preview="password-create">
            <AuthPasswordCreate location={this.props.location}/>
          </DialogAuth>
        );
      }
    }

    // if (section === 'signup') {
    if (this.props.popups.isSignupPopupVisible) {
      authPopup = 'signup';
      dialog = <SingUpNewContainer location={this.props.location}/>
    }
    // if (section === 'login') {
    if (this.props.popups.isLoginPopupVisible) {
      authPopup = 'login';
      dialog = <LoginContainer location={this.props.location}/>
    }

    let popup = (
      <div
        className={`popup ${
          is_closed ? 'popup_hide' : 'popup_show'
        } popup_state_${authPopup ? authPopup : section || ''} popup_state_${
          isTouch() ? 'touch' : 'not_touch'
        }`}
      >
        <div className="popup__overlay" onClick={hidePopUp}/>
        <div className={`popup__dialog popup__dialog_${authPopup ? authPopup : section || ''}`}>
          <button
            onClick={this.closePopupHandler}
            type="button"
            className="popup__close"
          />

          {dialog}
        </div>
      </div>
    );

    return dialog ? popup : null;
  }
}

Popup.propTypes = {
  is_closed: PropTypes.bool,
  focus: PropTypes.bool,
  skin: PropTypes.object,
  hidePopUp: PropTypes.func,
  section: PropTypes.string,
  item: PropTypes.shape({
    section: PropTypes.string,
    status: PropTypes.string,
    preview: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    title: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string,
    btnTitle: PropTypes.string
  }),
  popupData: PropTypes.shape({
    token: PropTypes.string,
    backUrl: PropTypes.string,
    paymentId: PropTypes.number
  })
};

const mapStateToProps = state => {
  return {
    popups: state.popups
  };
};

export default connect(mapStateToProps, {
  closeAuthPopup
})(Popup);
