import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { config } from '../../data';
import {PageTooltip} from '../../components';

export const LinkAbout = (props) => {

  const {
    children,
    tooltip
  } = props;

  let childName = tooltip ?
    (<PageTooltip
      title={tooltip.title}
      message={tooltip.message}
      position={tooltip.position}
      autoKill={tooltip.autoKill}
      autoKillCB={tooltip.autoKillCB}
      />)
    : null;

  return (
    <Link
      // если детелей больше 1 ссылка ведёт на разводящую страницу
      to={children && children.length > 1 ? `cabinet/${config.routes.child_selector}`: '/'}
      className={children && children.length > 1 ? 'link-about': 'link-about link-about_state_disabled'}
    >
      Чевостик
      {childName}
    </Link>
  )
};

LinkAbout.propTypes = {
  child_name: PropTypes.string,
  children: PropTypes.array
};
