import React, {Component} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';

import * as auth from './helpers/authenticationHelper';
import {config} from './data';

import {
  App,
  ErrorPage,
  LessonsContainer,
  GamesContainer,
  GameContainer,
  CabinetContainer,
  CabinetNotAuth,
  SelectTariffContainer,
  ChildContainer,
  UserSettingsContainer,
  ChildSelectorContainer,
  AuthContainer,
  VerifyPassword,
  BillingContainer,
  UserSettingsAuthBindVk,
  UserSettingsAuthBindFb,
  UserSettingsAuthBindOk,
  SuccessfulPayment,
  PopupManager
} from './containers';
import {LibraryContainer} from './containers/library';
import SocialAuthContainer from './containers/social-auth-container/social-auth-container';
import {CabinetAgreement} from './containers/cabinet/cabinet-agreement';
import TestContainer from './containers/test/test';
import LessonContainer from './containers/lesson/lesson';
import PreLoader from './components/pre-loader/pre-loader';
import queryString from 'query-string';

class RoutesApp extends Component {
  state = {
    mapRouteProps: {
      hideLoader: false,
      location: {
        pathname: '/',
        search: '',
        hash: ''
      },
      match: {
        path: '/',
        url: '/',
        isExact: true,
        params: {}
      }
    }
  }

  goToMap = (routeProps) => {
    if (auth.isLoggedIn() && !auth.getCurrentChild()) return <Redirect to={`/cabinet/${config.routes.child_selector}`}/>
    return <LessonsContainer {...routeProps}/>
  };

  goToLesson = (routeProps) => {
    if (!auth.isLoggedIn()) {
      return <Redirect to="/?popup=firstEnter"/>
    } else if (!auth.getCurrentChild()) {
      return <Redirect to={`/cabinet/${config.routes.child_selector}`}/>
    }

    return <LessonContainer lessonsScreenType={'lesson'} {...routeProps}/>
  };

  goToTest = (routeProps) => {
    if (!auth.isLoggedIn()) {
      return <Redirect to="/?auth=signup"/>
    }
    return <TestContainer {...routeProps}/>
  };

  goToGame = (routeProps) => {
    if (!auth.isLoggedIn()) {
      return <Redirect to="/?auth=signup"/>
    }

    return <GameContainer {...routeProps}/>
  };

  updateMapRouteProps = (routeProps) => {
    const {location: {pathname, search}} = routeProps;
    const query = queryString.parse(search);

    if (pathname.match(/\/lessons\/\d+/)) {
      routeProps.hideNav = true;
    }

    if (pathname === `/cabinet/${config.routes.child_selector}`) {
      routeProps.screenClass = 'child-overflow';
    }

    if (query.backToMobile) {
      routeProps.hideLoader = false;
    }

    if (JSON.stringify(routeProps) !== JSON.stringify(this.state.mapRouteProps)) {
      setTimeout(() => {
        this.setState({mapRouteProps: routeProps});
      }, 0);
    }
  }

  render() {
    return <App>
      <PreLoader/>
      <PopupManager/>
      <AuthContainer/>

      {this.goToMap(this.state.mapRouteProps)}

      <Switch>
        <Route exact path={['/lessons', '/', '/auth/password-create']} render={(props) => {
          props.hideLoader = true;
          this.updateMapRouteProps(props);
          return null
        }}/>

        <Route exact path="/lessons/:id" render={(props) => {
          this.updateMapRouteProps(props);
          return this.goToLesson(props);
        }}/>

        <Route exact path="/lessons/:id/tests/:id_test" render={(props) => {
          this.updateMapRouteProps(props);
          return this.goToTest(props);
        }}/>

        <Route path="/quests/:quest_id?" render={(props) => {
          this.updateMapRouteProps(props);
          return null;
        }}/>

        <Route exact path="/games" render={(props) => {
          this.updateMapRouteProps(props);
          return <GamesContainer {...props} />
        }}/>

        <Route exact path="/games/:id_game" render={(props) => {
          this.updateMapRouteProps(props);
          return this.goToGame(props);
        }}/>

        <Route exact path="/successful-payment" render={(props) => {
          props.hideLoader = true;
          this.updateMapRouteProps(props);
          return <SuccessfulPayment {...props} />
        }}/>

        <Route exact path="/library" render={(props) => {
          this.updateMapRouteProps(props);
          return <LibraryContainer {...props} />
        }}/>

        <Route exact path="/auth/social/:type" render={(props) => {
          this.updateMapRouteProps(props);
          return <SocialAuthContainer {...props} />
        }}/>

        <Route path={['/eula', '/cabinet']} render={(routeProps) => {
          return (
            <CabinetContainer>
              <Route path="/cabinet/settings">
                <UserSettingsContainer>
                  <Route path="/cabinet/settings/auth-bind/vk" render={(props) => {
                    this.updateMapRouteProps(props);
                    return <UserSettingsAuthBindVk {...props} />
                  }}/>
                  <Route path="/cabinet/settings/auth-bind/fb" render={(props) => {
                    this.updateMapRouteProps(props);
                    return <UserSettingsAuthBindFb {...props} />
                  }}/>
                  <Route path="/cabinet/settings/auth-bind/ok" render={(props) => {
                    this.updateMapRouteProps(props);
                    return <UserSettingsAuthBindOk {...props} />
                  }}/>
                </UserSettingsContainer>
              </Route>

              <Route path={'/eula'} render={(props) => {
                this.updateMapRouteProps(props);
                return <CabinetAgreement {...props} />
              }}/>

              <Route path={[
                `/cabinet/${config.routes.add_new_child}`,
                `/cabinet/child/:id/${config.routes.profile}`,
                '/cabinet/child/:id'
              ]}
                     render={(props) => {
                       this.updateMapRouteProps(props);
                       return <ChildContainer {...props} />
                     }}
              />

              <Route exact path={`/cabinet/${config.routes.child_selector}`} render={(props) => {
                if (!auth.isLoggedIn()) {
                  return <Redirect to="/?auth=signup"/>
                } else if (!auth.getCurrentChild() && props.location.pathname !== `/cabinet/${config.routes.child_selector}`) {
                  return <Redirect to={`/cabinet/${config.routes.child_selector}`}/>
                }

                this.updateMapRouteProps(props);

                return <ChildSelectorContainer {...props}/>
              }}
              />

              <Route path={`/cabinet/${config.routes.select_tariff}`} render={(props) => {
                this.updateMapRouteProps(props);
                return <SelectTariffContainer {...props} />
              }}/>

              <Route path={`/cabinet/${config.routes.verify_password}`} render={(props) => {
                this.updateMapRouteProps(props);
                return <VerifyPassword {...props} />
              }}/>

              <Route path={`/cabinet/${config.routes.billing}`} render={(props) => {
                this.updateMapRouteProps(props);
                return <BillingContainer {...props} />
              }}/>
            </CabinetContainer>
          )
        }}/>

        {/*<Route path="/prizes" component={PrizesContainer}/>*/}

        {/*<Route path="/first-enter" component={FirstEnter}/>*/}

        <Route path={`/${config.routes.not_registered}`} component={CabinetContainer}>
          <Route exact path="/" component={CabinetNotAuth}/>
        </Route>

        <Route path="*" status={404} component={ErrorPage}/>
      </Switch>
    </App>
  }

}

export default RoutesApp;
