import React, { useState } from 'react';
import { Alert, alertPosition } from '../../../components';
import { Spring, withBanner, bannerType } from '../../banners';
import {getCookie} from '../../../helpers/getCookie';

const SpringAlert = ({ type }) => {
  const [open, setOpen] = useState(true);
  let position = '';

  if (type === bannerType.big) {
    position = alertPosition.center;
  } else if (type === bannerType.small) {
    position = alertPosition.bottom;
  }

  function close() {
    setOpen(false);
    let now = new Date().getDate();
    document.cookie = `lastOpen=${now}`;
  }
  let now = new Date().getDate();


  console.log(getCookie('lastOpen'));
  return (
   <>
      {getCookie('lastOpen') !== now.toString() && (
    <Alert position={position} open={open}>
        <Spring type={type} handleButtonClick={close} />
    </Alert>
      )}
   </>
  );
};

export const SpringAlertBanner = withBanner(SpringAlert);
