import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { config } from '../../data';

export const LessonProgress = ({count, percent}) => {
  const removeNumber = document.body.clientWidth > 820
    ? 100
    : 73;

  const heightCircle = document.body.clientWidth > 820
    ? 52
    : 34

  const getHeight = (percentNumber) => {
    if (!document.querySelector('.lesson-progress')) return 0;
    const height = document.querySelector('.lesson-progress').offsetHeight;
    const persentSmall = (height * (percentNumber / 100) - heightCircle / 2) / (height / 100);
    const persentBig = (height * (percentNumber / 100) + heightCircle / 2) / (height / 100);
    const onePersonCircle = (persentBig - persentSmall) / 100;
    if (percent < persentSmall) return 0;
    if (percent > persentBig) return heightCircle;
    return ((((percent - persentSmall) / onePersonCircle)) / 100 + onePersonCircle * 1) * heightCircle
  }

  return <div className="lesson-progress">
    {/* For testing */}
    {/* <div style={{position: 'absolute', top: '40px', left: '-40px', fontSize: '30px', fontWeight: 700, zIndex: 20, color: 'white'}}>{percent}</div> */}
    <div className="lesson-progress__count">{count}</div>
    <div className="lesson-progress__bar" style={{height: `calc((100% - ${removeNumber}px) * ${percent / 100})`}}/>
    <div className="lesson-progress__50" />
    <div className="lesson-progress__50-circle" style={{height: `${getHeight(config.configStartShow.firstStarPercentShow)}px`}}/>
    <div className="lesson-progress__70" />
    <div className="lesson-progress__70-circle" style={{height: `${getHeight(config.configStartShow.secondStarPercentShow)}px`}}/>
    <div className={c('lesson-progress__50-star', {'lesson-progress__50-star--show': percent >= config.configStartShow.firstStarPercentShow})} />
    <div className={c('lesson-progress__70-star', {'lesson-progress__70-star--show': percent >= config.configStartShow.secondStarPercentShow})} />
  </div>
};

LessonProgress.propTypes = {
  percent: PropTypes.number,
  count: PropTypes.number,
};
