import React from 'react';
import PropTypes from 'prop-types';

import {
  ChildQuest
} from '../../components';

export const ChildQuestSection = (props) => {
  const {
    quest,
    lessons
  } = props;
  let questLessons = [];
  let filteredLessons = [];
  let filterClass = ''; // finished, not_started, not_finished
  let testCompleted = [];

  let childQuest = null;

  window.devMode && console.log('ChildQuestSection', lessons, quest);

  if (quest && quest.hasOwnProperty('lessons') && quest.lessons.length) {
    quest.lessons.forEach(item => {
      questLessons.push({
        id: item.id
      })
    });
  }

  if (quest && quest.hasOwnProperty('final_lesson')) {
    questLessons.push({
      id: quest.final_lesson.id
    });
  }

  if (lessons && lessons.length) {
    lessons.forEach(lesson => {
      questLessons.forEach(questLesson => {
        if (lesson.id === questLesson.id) {
          filteredLessons.push(lesson)
        }
      })
    });
  }

  if (filteredLessons.length > 0) {
    childQuest = filteredLessons.map((item, index) => {
      return (
        <ChildQuest
          key={index}
          lesson={item}
        />
      )
    });

    filteredLessons.forEach(item => {
      if (item.test.is_completed) {
        testCompleted.push(item)
      }
    });

    if (filteredLessons.length === testCompleted.length) {
      filterClass = 'finished';
    }

    if (filteredLessons.length > testCompleted.length) {
      filterClass = 'not_finished';

      if (testCompleted.length === 0) {
        filterClass = 'not_started';
      }

    }
  }

  return (
    <div className="child-quest-section" data-tags={filterClass}>
      <h2 className="child-quest-section__title">{quest.name}</h2>
      {childQuest}
    </div>
  );
};

ChildQuestSection.propTypes = {
  quest: PropTypes.object,
  lessons: PropTypes.array
};
