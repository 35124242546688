export const config = {
  base_url: `${
    window.location.host.includes('stage')
      ? 'https://stage.chevostik.ru'
      : 'https://game.chevostik.ru'
  }`,

  // значение в % для центрирования маштабируемых контейнеров
  default_center_axis_value: -50,

  sprite_directory: '/static/media/',

  // РОУТИНГ

  routes: {
    settings: 'settings',
    payment: 'payment',
    select_tariff: 'select_tariff',
    verify_password: 'verify_password',
    billing: 'billing',
    profile: 'profile',
    add_new_child: 'add-new-child',
    not_registered: 'not_registered',
    treasury: '/prizes',
    child_selector: 'child-selector',
    cabinet_settings: '/cabinet/settings',
    quests: 'quests',
    enter: '/?auth=signup',
    games: '/games',
    scoreTable: '/?popup=scoreTable',
    infantCabinet: '/?popup=infantCabinet',
    coinShop: '/?popup=coinShop',
    blog: 'http://blog.chevostik.ru',
    shop: 'http://shop.chevostik.ru/products/450611-chevostik-ru-podpiska-spetspredlozhenie-1-god',
    library: '/library',
    about: '/about',
    contacts: '/contacts',
    subscription: '/subscription',
    cabinet: '/cabinet',
    child: '/cabinet/child'
  },

  // АВАТАРЫ

  avatars: [1, 2, 3, 4, 5, 6, 7, 8, 9],

  // УРОКИ

  configStartShow: {
    firstStarPercentShow: 50,
    secondStarPercentShow: 75,
    thirdStarPercentShow: 100
  },

  lesson_not_paid: {
    status: 'not_paid',
    btn: 'О подписке',
    route: '/subscription'
  },

  lesson_not_registered: {
    status: 'not_registered',
    btn: 'Зарегистрироваться',
    route: '/?auth=signup'
  },

  lesson_quest: {
    status: 'quest',
    btn: 'Показать маршрут',
    route: '/quests/'
  },

  // НАВИГАЦИЯ

  lessons_page_main_nav: [
    {
      url: '/cabinet',
      class_name: 'main-nav__item_cabinet',
      name: 'Кабинет'
    },
    {
      url: '/prizes',
      class_name: 'main-nav__item_prizes',
      name: 'Призы'
    },
    {
      url: '/games',
      class_name: 'main-nav__item_games',
      name: 'Игры'
    }
  ],

  prizes_page_main_nav: [
    {
      url: '/',
      class_name: 'main-nav__item_map',
      name: 'Карта'
    },
    {
      url: '/games',
      class_name: 'main-nav__item_games main-nav__item_prizes_to_games',
      name: 'Игры'
    }
  ],

  // страница с играми
  games_page_main_nav: [
    {
      url: '/',
      class_name: 'main-nav__item_map',
      name: 'Карта'
    },
    {
      url: '/prizes',
      class_name: 'main-nav__item_prizes main-nav__item_games_to_prizes',
      name: 'Призы'
    }
  ],

  // страницы, где сбоку только карта
  main_nav_map: [
    {
      url: '/',
      class_name: 'main-nav__item_map',
      name: 'Карта'
    }
  ],

  game_page_main_nav: [
    {
      url: '/',
      class_name: 'main-nav__item_map',
      name: 'Карта'
    },
    {
      url: '/games',
      class_name: 'main-nav__item_games',
      name: 'Игры'
    }
  ],

  // КАРТА С УРОКАМИ:

  map_lessons_img: [
    //process.env.PUBLIC_URL + '/img/map/island.png',
    //process.env.PUBLIC_URL + '/img/map/waves.jpg'
  ],

  map_lessons_book_popup: [
    process.env.PUBLIC_URL + '/img/map/book/Book.svg',
    process.env.PUBLIC_URL + '/img/map/book/Chevostik_book.png',
    process.env.PUBLIC_URL + '/img/map/book/Chevostik_book@2x.png',
    process.env.PUBLIC_URL + '/img/map/book/Btn_orange_arrow.svg'
  ],

  map_lessons_book_popup_mobile: [
    process.env.PUBLIC_URL + '/img/map/book/mobile/Book_mobile.svg',
    process.env.PUBLIC_URL + '/img/map/book/mobile/Chevostik_book_mobile.png',
    process.env.PUBLIC_URL +
    '/img/map/book/mobile/Chevostik_book_mobile@2x.png',
    process.env.PUBLIC_URL + '/img/map/book/mobile/btn_arrow_mobile.svg'
  ],

  // СОЦ СЕТИ

  social_links: {
    vk: 'https://vk.com/chevostik_ru',
    fb: 'https://www.facebook.com/chevostik/',
    instagram: 'https://www.instagram.com/chevostik_ru'
  },

  // АВТОРИЗАЦИЯ

  social_auth: {
    vk: {
      client_id: 6032624,
      bind_redirect_uri: 'cabinet/settings/auth-bind/vk/',
      login_redirect_uri: 'auth/social/vk'
    },
    fb: {
      client_id: 310144479416802,
      bind_redirect_uri: 'cabinet/settings/auth-bind/fb/',
      login_redirect_uri: 'auth/social/fb'
    },
    ok: {
      client_id: 'было_не_нужно_на_май_2017__должно_быть_числом',
      bind_redirect_uri: 'было_не_нужно_на_май_2017',
      login_redirect_uri: 'было_не_нужно_на_май_2017'
    }
  },

  social_auth_redirect_uri: '/auth/social/',

  // КАБИНЕТ РОДИТЕЛЯ

  cabinet: {
    not_registered: {
      header_title: 'Прогресс ребёнка'
    },

    routes_link: {
      title: 'Маршруты',
      start: 'Начатые',
      not_start: 'Неначатые'
    },

    routes_filter: [
      {
        tag: 'all',
        name: 'Все'
      },
      {
        tag: 'finished',
        name: 'Пройденные'
      },
      {
        tag: 'not_finished',
        name: 'Начатые'
      },
      {
        tag: 'not_started',
        name: 'Неначатые'
      }
    ],

    quests_lessons_in_row: 8,
    breakpoint_large: 1600
  },

  // ССЫЛКИ

  //breadhead_link: 'http://breadhead.ru',

  // ТИПЫ ИГР

  game_types: {
    memory_game: 'memory-game',
    collect_puzzle: 'collect-puzzle',
    find_hidden_hero: 'find-hidden-hero',
    find_hidden_item: 'find-hidden-item',
    pick_up_silhouette: 'pick-up-silhouette',
    collect_item: 'collect-item',
    collect_item_v2: 'collect-item-v2',
    logical_chain: 'logical-chain'
  },

  // ИГРЫ

  games_filter_default: 'all',

  games_lesson_or_test_not_finished: {
    status: 'lesson_or_test_not_finished',
    title: 'Послушай историю и пройди тест, чтобы открыть эту игру',
    btn: 'Слушать',
    icon: 'icon-closed-popup'
  },

  games_not_paid_subscription: {
    status: 'not_paid_subscription',
    title: 'Игра закрыта',
    text: 'Оформите подписку, чтобы получить доступ к этой и другим играм',
    btn: 'О подписке',
    icon: 'icon-closed-popup'
  },

  games_user_not_auth: {
    status: 'user_not_auth',
    title: 'Игра закрыта',
    text: 'Зарегистрируйтесь и получите доступ к трем бесплатным историям',
    btn: 'Зарегистрироваться',
    icon: 'icon-closed-popup'
  },

  games_not_shared: {
    status: 'not_shared',
    title: 'Игра закрыта',
    text: 'Поделись ссылкой на нас — получи бонусную игру',
    btn: 'В кабинет',
    icon: 'icon-closed-popup'
  },

  games_routes: {
    status: 'routes',
    title: 'Пройди по тайному маршруту, чтобы открыть эту игру',
    btn: 'Показать маршрут',
    icon: 'icon-star-popup'
  },

  games_logical_chain: {
    status: 'logical_chain',
    title: 'Пройди любой маршрут, чтобы открыть эту игру',
    btn: 'Показать маршрут',
    icon: 'icon-star-popup'
  },

  games_lesson_is_free: {
    status: 'lesson_is_free',
    title: 'Послушай историю и пройди тест, чтобы открыть эту игру',
    btn: 'Слушать',
    icon: 'icon-free-popup'
  },
  games_popup_preview:
    process.env.PUBLIC_URL + '/img/popup/games_closed_popup.jpg',
  games_popup_preview_routes:
    process.env.PUBLIC_URL + '/img/popup/games_quest_popup.jpg',

  base_game_container: {
    width: 1920,
    height: 1080
  }
};
