import {HIDE_POPUP, SHOW_POPUP, UPDATE_COINS, UPDATE_POINTS, TOGGLE_NAVBAR, TOGGLE_PRELOADER} from './actionTypes';

export const closeAuthPopup = popup => ({
  type: HIDE_POPUP,
  payload: {
    popup
  }
});

export const openAuthPopup = popup => ({
  type: SHOW_POPUP,
  payload: {
    popup
  }
});

export const updateChildCoins = coins => ({
  type: UPDATE_COINS,
  payload: {
    coins
  }
});

export const updateChildPoints = points => ({
  type: UPDATE_POINTS,
  payload: {
    points
  }
});

export const showNav = show => ({
  type: TOGGLE_NAVBAR,
  payload: {
    show
  }
});

export const setPreloader = type => ({
  type: TOGGLE_PRELOADER,
  payload: {
    type
  }
});
