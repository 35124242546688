import React, {Component} from 'react';
import {withRouter} from 'react-router'
import * as auth from '../../helpers/authenticationHelper';
import {onEscapeKeyPress} from '../../helpers';

import {
  CabinetMenu,
  CabinetDemoMessage
} from '../../components';
import Popup from '../../components/popup/popup';
import {config} from '../../data';

class CabinetContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_popup_closed: true,
      cabinet_popup: {
        status: '',
        title: '',
        text: '',
        url: ''
      }
    };
  }

  render() {
    const user = auth.getProfile();

    return (
      <div className={'cabinet' + (this.props.location.pathname === `/cabinet/${config.routes.child_selector}` ? ' child-selector-mode' : '') + (!this.state.is_popup_closed || this.props.location.pathname.indexOf('/cabinet/select_tariff') === 0 ? ' has-popup' : '')}>
        <div className="cabinet__inner">
          {window.innerWidth > 900 && (<div className="cabinet__menu">
            <CabinetMenu showPopUp={this.showPopUp}/>
          </div>)}


          <div className="cabinet__message">
            {
              (user && user.subscription && user.subscription.status === 'soon_finished') &&
              <CabinetDemoMessage message={user.subscription.message}/>
            }
          </div>

          {this.props.children}

          <Popup
            is_closed={this.state.is_popup_closed}
            hidePopUp={this.hidePopUp}
            location={this.props && this.props.location}
            item={this.state.cabinet_popup}
          />
        </div>
      </div>
    );
  }

  showPopUp = (event) => {
    window.addEventListener('keydown', () => onEscapeKeyPress(this.hidePopUp));

    let status = event.currentTarget.getAttribute('data-status');
    let section = event.currentTarget.getAttribute('data-section');
    let title = event.currentTarget.getAttribute('data-title');
    let text = event.currentTarget.getAttribute('data-text');
    let url = event.currentTarget.getAttribute('data-url');

    this.setState({
      cabinet_popup: {
        status: status,
        section: section,
        title: title,
        text: text,
        url: url
      },
      is_popup_closed: false
    })
  }

  hidePopUp = () => {
    window.removeEventListener('keydown', () => onEscapeKeyPress(this.hidePopUp));

    this.setState({
      is_popup_closed: true,
      cabinet_popup: {
        status: '',
        title: '',
        text: '',
        url: ''
      }
    })
  }
}

const componentWithRouter = withRouter(CabinetContainer)
export {componentWithRouter as CabinetContainer}
