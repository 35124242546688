import React from 'react';
import PropTypes from 'prop-types';

export const Tooltip = (props) => {
  const {title, position, topIndent} = props;

  return (
    <div
      className={`tooltip tooltip_${position} ${topIndent ? 'tooltip_indent' : ''}`}
      role="tooltip"
      style={{transform: 'translateX(-50%)'}}
    >
      <div className="tooltip__arrow"/>
      <div className="tooltip__inner">{title}</div>
    </div>
  );
};

Tooltip.propTypes = {
  title: PropTypes.string,
  position: PropTypes.string,
  topIndent: PropTypes.bool
};
