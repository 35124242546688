import React from 'react';
import PropTypes from 'prop-types';

import {
  ChildQuestSection
} from '../../components';

export const ChildQuests = (props) => {

  const {
    quests,
    lessons
  } = props;

  let questItem = quests.map((item, index) => {
    return (
      <ChildQuestSection
        key={index}
        quest={item}
        lessons={lessons}
      />
    )
  });

  return (
    <div className="child-quests js-quests-list" data-filter="all">
      {questItem}
    </div>
  );
};

ChildQuests.propTypes = {
  quests: PropTypes.array,
  lessons: PropTypes.array
};
