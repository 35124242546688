/* eslint-disable quotes */

// HACK: в highlightLessons вместо урока можно скармливать нужные картинки
export const toursData = [
  {
    img: [{
      path: '/img/tour/chev_tour1.png',
      style: {
        width: '330px'
      },
      className: 'tours__chevostik'
    }],
    icon: '/img/tour/icon_0.png',
    description: 'Ого, сколько значков! Каждый из них - увлекательный интерактивный урок. Здесь можно и нужно на все нажимать!'
  },
  {
    img: [{
      path: '/img/tour/chev_tour2.png',
      style: {
        width: '300px',
        left: '35px'
      },
      className: 'tours__chevostik'
    }],
    highlightLessons: [
      {
        id: 3,
        access: {
          is_open: true
        },
        preview_image: {
          large: ''
        },
        map: {
          css: ".lesson[data-map-position='auto_i_transport_tour']{width:162px;height:150px;top:490px;left:440px;}",
          icon: "/img/tour/lessonIcon3.png",
          position: "auto_i_transport_tour",
        }
      },
      {
        id: 7,
        access: {
          is_open: true
        },
        preview_image: {
          large: ''
        },
        map: {
          css: ".lesson[data-map-position='phisics_tour']{width:138px;height:126px;top:180px;left:1335px;}",
          icon: "/img/tour/lessonIcon7.png",
          position: "phisics_tour"
        }
      },
      {
        id: 9,
        access: {
          is_open: true
        },
        preview_image: {
          large: ''
        },
        map: {
          css: ".lesson[data-map-position='space_tour']{width:143px;height:122px;top:68px;left:443px;}",
          icon: "/img/tour/lessonIcon9.png",
          position: "space_tour"
        }
      },
      {
        id: 55,
        access: {
          is_open: true
        },
        preview_image: {
          large: ''
        },
        map: {
          css: ".lesson[data-map-position='dino-2_tour']{width:136px;height:121px;top:410px;left:759px;}\r\n",
          icon: "/img/tour/lessonIcon55.png",
          position: "dino-2_tour"
        }
      },
      {
        id: 73,
        access: {
          is_open: true
        },
        preview_image: {
          large: ''
        },
        map: {
          css: ".lesson[data-map-position='mozg_tour']{width:128px;height:128px;top:811px;left:1229px;}",
          icon: "/img/tour/lessonIcon73.png",
          position: "mozg_tour"
        }
      }
    ],
    icon: '/img/tour/test.svg',
    description: 'В каждом уроке есть тест - и это не проверка, а увлекательное повторение важной информации через игру.'
  },
  {
    img: [{
      path: '/img/tour/chev_tour3.png',
      className: 'tours__chevostik'
    }],
    highlightLessons: [
      {
        id: 2000,
        access: {
          is_open: true
        },
        preview_image: {
          large: ''
        },
        map: {
          css: ".lesson[data-map-position='path_tour']{width:1408px;height:613px;top:330px;left:371px;}",
          icon: "/img/tour/path.svg",
          position: "path_tour"
        }
      },
      {
        id: 3,
        access: {
          is_open: true
        },
        preview_image: {
          large: ''
        },
        map: {
          css: ".lesson[data-map-position='auto_i_transport_tour']{width:162px;height:150px;top:490px;left:440px;}",
          icon: "/img/tour/lessonIcon3.png",
          position: "auto_i_transport_tour",
        }
      },
      {
        id: 57,
        access: {
          is_open: true
        },
        preview_image: {
          large: ''
        },
        map: {
          css: ".lesson[data-map-position='tretyakovskaya3_tour']{width:125px;height:123px;top:454px;left:1097px;}",
          icon: "/img/tour/lessonStar.png",
          position:	"tretyakovskaya3_tour"
        }
      },
      {
        id: 4,
        access: {
          is_open: true
        },
        preview_image: {
          large: ''
        },
        map: {
          css: ".lesson[data-map-position='chemistry_tour']{width:125px;height:123px;top:248px;left:312px;}",
          icon: "/img/tour/lessonStar.png",
          position: "chemistry_tour"
        }
      },
      {
        id: 78,
        access: {
          is_open: true
        },
        preview_image: {
          large: ''
        },
        map: {
          css: ".lesson[data-map-position='ecology_tour']{width:168px;height:134px;top:880px;left:1719px;}",
          icon: "/img/tour/lessonIcon78.png",
          position: "ecology_tour"
        }
      },
      {
        id: 73,
        access: {
          is_open: true
        },
        preview_image: {
          large: ''
        },
        map: {
          css: ".lesson[data-map-position='mozg_tour']{width:128px;height:128px;top:811px;left:1229px;}",
          icon: "/img/tour/lessonIcon73.png",
          position: "mozg_tour"
        }
      }
    ],
    icon: '/img/tour/icon_2.png',
    description: 'Зарегистрируйся и получи 3 дня бесплатного полного доступа ко всем урокам. В течение пробного периода приобрести подписку можно со скидкой!',
  },
  {
    img: [
      {
        path: '/img/tour/chev_tour4.png',
        className: 'tours__chevostik'
      },
      {
        path: '/img/tour/menu_prizes.png',
        className: 'tours__prizes'
      }
    ],
    icon: '/img/tour/icon_3.png',
    description: 'Это сокровищница. Здесь хранятся все призы и бонусы. проходи уроки, собирай коллекцию и открывай с их помощью секретные знания!'
  },
  {
    img: [
      {
        path: '/img/tour/chev_tour5.png',
        className: 'tours__chevostik'
      },
      {
        path: '/img/tour/menu.png',
        className: 'tours__menu'
      }
    ],
    description: 'Родительский кабинет - удобный наблюдательный пункт для мам и пап. Здесь взрослые смогут следить за твоими успехами и достижениями!'
  },
  {
    img: [
      {
        path: '/img/tour/chev_tour6.png',
        style: {
          left: '45px'
        },
        className: 'tours__chevostik'
      },
      {
        path: '/img/tour/menu_game.png',
        className: 'tours__game'
      }
    ],
    icon: '/img/tour/icon_5.png',
    description: 'Игры могут быть невероятно полезными. Поиграем вместе и ты увидишь, как много нового ты можешь запомнить!'
  },
  {
    img: [{
      path: '/img/tour/chev_tour7.png',
      style: {
        left: '45px'
      },
      className: 'tours__chevostik'
    }],
    linkBtn: {
      name: 'Зарегистрироваться',
      link: '/?auth=signup',
    },
    description: 'Вот это да! Подписка стоит как чашка кофе, но открывает доступ к целому миру полезных знаний через игру. «Приключения Чевостика» - это настоящая библиотека интересных тем!'
  }
];
