import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import cn from 'classnames';
import style from './sale.module.sass';

export const Sale = ({type, handleButtonClick}) => {
  return (
    <div className={cn(style.saleBanner, style[type])}>
      <img alt={'promo'} style={{width:'110px',position:'absolute',right:'10px',top:'25px'}} src={'/img_static/route-promo.png'}/>
      <div style={{fontWeight: '600', fontSize: '30px', marginBottom:'10px'}}>Все курсы<br/>Чевостика<br/>на год</div>
      <div>✔ более 100 игр</div>
      <div>✔ более 100 тестов</div>
      <div>✔ более 100 приключений</div>

      <div style={{display: 'flex',marginTop:'10px'}}>
        <div className={style.promo_price}>Всего<br/> 1990Р</div>
        <div style={{
          marginLeft: '10px',
          padding: '5px',
          fontWeight: 'normal',
          fontSize: '21px',
          color: 'rgba(0,0,0,0.6)'
        }}>Вместо<br/><span style={{textDecoration: 'line-through'}}>6600₽</span><br/>выгода<br/>4610₽

        </div>
      </div>
      <Link
        to="/cabinet/select_tariff/"
        className={style.button}
        onClick={handleButtonClick}
        type="button"
      >
        Купить со скидкой
      </Link>

    </div>
  );
};

Sale.propTypes = {
  type: PropTypes.string.isRequired,
  handleButtonClick: PropTypes.func
};
