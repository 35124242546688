import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { config } from '../../data';

export const ChildQuestsLink = () => {

  return (
    <Link to="/quests" className="child-quests-link cabinet-header__routes">
       <i></i>
       <span className="child-quests-link__title">{config.cabinet.routes_link.title}</span>
    </Link>
  );
};

ChildQuestsLink.propTypes = {
  child_id: PropTypes.number,
};
