import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {buildAvatarUrl, isTouch} from '../../helpers';
import * as auth from '../../helpers/authenticationHelper';

import {
  ChildQuestsLink
} from '../../components';

import { config } from '../../data';

export const ChildHeader = (props) => {

  const {
    child,
    is_back_link,
    is_profile_page
  } = props;
  let user = auth.getProfile();

  let title = null;
  let linkBack = null;
  let childId = child.id;
  let childName = child.name;
  let routes = <ChildQuestsLink child_id={childId} />;

  if (is_back_link) {
    linkBack = <Link
      to={`/cabinet/child/${childId}`}
      className="cabinet-header__link-back"
    />
  }

  let ava = buildAvatarUrl('');

  if (user) {
    let child = user.children.find(c => c.id === user.currentChildId);
    ava = buildAvatarUrl(child);
  }

  if (is_profile_page) {
    title = <span
      className={`cabinet-header__user cabinet-header__user_state_disabled cabinet-header__user_type_${auth.getCurrentAvatar()}`}
    >
      <i style={{
        backgroundImage: ava,
      }}/>
      {childName}
    </span>
  } else {
    title = <Link
      to={`/cabinet/child/${childId}/${config.routes.profile}`}
      className={`cabinet-header__user cabinet-header__user_type_${auth.getCurrentAvatar()}`}
    >
      <i style={{
        backgroundImage: ava,
      }}/>
      {childName}
      <span className={`cabinet-header__user-btn ${isTouch()? '' : 'btn'}`}>
      Заполнить анкету
    </span>
    </Link>;
  }

  return (
    <header className={`cabinet-header ${isTouch() ? 'cabinet-header_state_touch' : ''}`}>
      <div className="wrapper wrapper_cabinet">

        <div className="cabinet-header__profile">
          {linkBack}
          {title}
        </div>

        {routes}
      </div>
    </header>
  );
};

ChildHeader.propTypes = {
  child: PropTypes.object,
  is_back_link: PropTypes.bool,
  is_profile_page: PropTypes.bool,
};
