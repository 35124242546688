import {TOGGLE_PRELOADER, TOGGLE_NAVBAR} from '../actionTypes';

const initialState = {
  preloaderType: '',
  showNavbar: true
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_PRELOADER: {
      return {
        ...state,
        preloaderType: action.payload.type
      };
    }

    case TOGGLE_NAVBAR: {
      return {
        ...state,
        showNavbar: action.payload.show
      };
    }

    default:
  }
  return state;
}
