import React from 'react';

import {
  config
} from '../../data'


export const ChildQuestsFilter = () => {

  let filterItem = config.cabinet.routes_filter.map((item, index) => {

    return (
      <label
        key={index}
        htmlFor={`type-${item.tag}`}
        className="js-quests-types child-quests-filter__label"
      >
        <input
          className="child-quests-filter__input js-quests-type"
          type="radio"
          name="type"
          value={item.tag}
          id={`type-${item.tag}`}
          defaultChecked={index === 0}
        />

        <span>
            {item.name}
          </span>

      </label>
    )
  });

  return (
    <nav className="child-quests-filter">
      <h3 className="child-quests-filter__title">Маршруты</h3>

      {filterItem}

    </nav>
  );
};
